<template>
  <div>
    <el-row>
      <el-col>
        <el-form :inline='true'>
          <el-form-item >
            <el-select v-model="filters.register" style="width: 120px" placeholder="考位类型" filterable clearable>
              <el-option
                  v-for="item in examPosition"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

<!--          <el-form-item label="">-->
<!--            <el-select v-model="filters.profession" style="width: 120px" placeholder="注册状态" filterable clearable>-->
<!--              <el-option-->
<!--                  v-for="item in professionType"-->
<!--                  :key="item.id"-->
<!--                  :label="item.professionFuncName"-->
<!--                  :value="item.id"-->
<!--              >-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
          <el-form-item label="">
            <el-select v-model="filters.registerStatus" style="width: 120px" placeholder="注册状态" filterable clearable>
              <el-option
                  v-for="item in professionType"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
	        <el-form-item label="">
		        <el-select v-model="filters.examSeatStatus" style="width: 120px" placeholder="启用状态" filterable clearable>
			        <el-option
					        v-for="item in professionType1"
					        :key="item.value"
					        :label="item.name"
					        :value="item.value"
			        >
			        </el-option>
		        </el-select>
	        </el-form-item>
          <el-form-item label="">
            <el-cascader
                v-model="filters.skillType"
                :options="organizeNums"
                @change="handleChange"
                :props="defaultProps"
                lazy
                :show-all-levels="false"
                clearable
                style="width: 80%"
                placeholder="组织管理"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item>
            <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <div style="margin-bottom: 10px">
      <span v-for="item in pointNums">
      {{item.examSeatTypeName}}:{{item.identifyPointNum}}个鉴定点 &nbsp;&nbsp;
    </span>
    </div>
    <!-- 列表 -->
    <el-table
        :data="tableData"
        border
        style="width: 100%"
        v-loading="listLoading"
        ref="multipleTable"
        @current-change='currentChange'
        :highlight-current-row='true'
    >
      <el-table-column type='index' label="序号" width='80' align="center" :index='indexMethod'></el-table-column>
      <el-table-column prop="examSeatTypeValue" label="考位类型" width="" align="center" ></el-table-column>
      <el-table-column prop="oproomBrandName" label="品牌名称" width="" align="center" ></el-table-column>
      <el-table-column prop="examSeatCode" label="考位ID(注册码)" width="130" align="center" ></el-table-column>
      <el-table-column prop="machineCode" label="设备唯一码" width=""  align="center" ></el-table-column>
      <el-table-column prop="ddIdExamVersionValue" label="版本号" width="100"  align="center" ></el-table-column>
      <el-table-column prop="registerStatus" label="注册状态"  width='100' align="center" >
        <template #default='scope'>
          <el-tag v-if='scope.row.registerStatus === 1' type='success'>已注册</el-tag>
          <el-tag v-else type='danger'>未注册</el-tag>
        </template>
      </el-table-column>
	    <el-table-column prop="ddIdExamSeatStatus" label="启用状态"  width='100' align="center" >
	    <template #default='scope'>
		    <el-tag v-if='scope.row.ddIdExamSeatStatus === 1' type='success'>已启用</el-tag>
		    <el-tag v-if='scope.row.ddIdExamSeatStatus !== 1' type='danger'>已禁用</el-tag>
	    </template>
    </el-table-column>
	    
      <el-table-column prop="machineBindingTime" label="注册日期" width="180" align="center" ></el-table-column>
      <el-table-column prop="name" label="考位名称" width="" align="center" ></el-table-column>
      <el-table-column prop="departmentIdValue" label="所属机构" width="" align="center" ></el-table-column>
<!--      <el-table-column prop="departmentId" label="所属组织ID" width="" align="center" ></el-table-column>-->
    </el-table>
    <!--底部工具条-->
    <el-col :span="24" class="pageBar">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pages.pageIndex"
          :page-sizes="pages.pageArr"
          :page-size="pages.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pages.dataCount">
      </el-pagination>
    </el-col>
    <el-dialog
        :title= "editFalse?'编辑':'新建考位'"
        :visible.sync="dialogs.addType.dialog"
        width="40%"
        :close-on-click-modal='false'
    >
      <el-form :model="addForm"  ref="addFormRef" label-width="140px">
        <el-form-item label="考位类型：">
          <el-select v-model="addForm.examSeatTypeId" @change="brandListChange" style="width: 80%" placeholder="考位类型" filterable clearable>
            <el-option
                v-for="item in examPosition"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="组织管理：">
<!--          <el-select v-model="addForm.departmentId" style="width:80%" placeholder="组织管理" filterable clearable>-->
<!--            <el-option-->
<!--                v-for="item in skillTypes"-->
<!--                :key="item.value"-->
<!--                :label="item.name"-->
<!--                :value="item.value"-->
<!--            >-->
<!--            </el-option>-->
<!--          </el-select>-->
          <el-cascader
              v-model="addForm.departmentId"
              :options="organizeNums"
              @change="handleChange"
              :props="defaultProps"
              lazy
              :show-all-levels="false"
              clearable
              style="width: 80%"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="考位名称：" >
          <el-input style="width: 80%"  v-model="addForm.name" placeholder=""></el-input>
        </el-form-item>
<!--        <el-form-item label="考位ID（注册码）：" >-->
<!--          <el-input style="width: 80%" disabled v-model="addForm.examSeatTypeId" placeholder=""></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="设备唯一码：">-->
<!--          <el-input style="width: 80%" disabled v-model="addForm.id" placeholder=""></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label='状态：' prop=''>
          <template>
            <el-radio-group v-model="addForm.ddIdExamSeatStatus">
              <el-radio :label="1">启用</el-radio>
              <el-radio :label="2">禁用</el-radio>
            </el-radio-group>
          </template>
        </el-form-item>
	      <el-form-item label="品牌列表：" v-if="brandIsDisable">
		      <el-select v-model="addForm.oproomBrandId"  placeholder="启用状态" style="width: 80%" filterable clearable>
			      <el-option
					      v-for="item in brandList"
					      :key="item.id"
					      :label="item.brandName"
					      :value="item.id"
			      >
			      </el-option>
		      </el-select>
	      </el-form-item>
      </el-form>
      <span v-if="!editFalse" slot="footer" class="dialog-footer">
                <el-button @click="dialogs.addType.dialog = false">取 消</el-button>
                <el-button type="primary" @click="addConfirm" :loading="loading">确认</el-button>
            </span>
      <span v-else slot="footer" class="dialog-footer">
                <el-button @click="dialogs.addType.dialog = false">取 消</el-button>
                <el-button type="primary" @click="editConfirm" :loading="loading">确认</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import {
	addExamSeat,
	updateExamSeat,
	getAreaDictionaryList,
	getExamSeatTypeList,
	resetExamSeat,
	getOproomProfessionDirList,
	getExamPointNumByExamSeatType,
	getExamSeatList, getOrganizationTree, addBatchExamSeat, getOproomBrandList,
} from '../../api/api'

export default {
  components:{Toolbar},
  data() {
    return {
      defaultProps: {
        children: "children",
        hasChildren: "hasChildren",
        label: "name",
        value:'id' ,//可要可不要
	      checkStrictly: true,
	      // multiple:true,
      },
      buttonList:[],
      tableData: [],
      areaArr: [],
      listLoading: false,
      //关于分页的obj
      pages: {
        pageSize: 20,//默认每页条数
        pageArr: [10, 20, 30, 40],//默认每页条数选择
        pageIndex: 1, //默认进入页
        dataCount: 20, //默认总页数
      },
      filters: {
        profession:undefined,
        skillType:[],
        name:undefined,
        register:undefined,
	      examSeatStatus:undefined,
	      registerStatus:undefined,
      },
      dialogs:{
        addType:{
          dialog:false,
        },
      },
      addForm:{
        ddIdExamSeatStatus:2,//考位状态 （1，启用；2，禁用）
        departmentId:[],//组织结构ID
        examSeatTypeId:undefined,//考位类型ID
        name:undefined,//考位名称
        schoolCode:undefined,//学校编号
	      oproomBrandId: undefined,//品牌ID
      },
      loading:false,
      addFormRules:[],
      professionType: [
        {value:false,name:'未注册'},
        {value:true,name:'已注册'},
      ],
	    professionType1: [
		    {value:1,name:'已启用'},
		    {value:2,name:'已禁用'},
	    ],
      editFalse:false,
      pointName:[],
      grade:[],
      examPosition:[],
      skillTypes:[
        {value:1,name:'关键技能'},
        {value:2,name:'非关键技能'},
      ],
      registers:[],
      pointNums:[],
      organizeNums:[],
	    brandList : [],
	    brandIsDisable:false,
    }
  },
  methods: {
	  brandListChange(val){
			const objBrand = this.examPosition.filter(item=>item.id === val)
		  console.log(val,objBrand,'pinpai')
		  if (objBrand[0].isRelationBrand){
			  this.brandList = objBrand[0].brandList
				setTimeout(()=>{
					this.brandIsDisable = true
				},10)
		  }else {
			  this.brandIsDisable = false
		  }
	  },
	  //品牌列表
	  brandDataList(){
		  let params = {
			  pageIndex: 1,
			  pageSize: 200,
			  ddCommonStatus: 1
		  }
		  getOproomBrandList(params).then(res => {
			  let result = res.data
			  if(result.success) {
				  this.brandList = result.response.data
			  }
		  })
	  },
		//重置
	  reset(){
		  if (!this.needData){
			  this.$message({
				  message:'请选择一条数据',
				  type:'warning'
			  })
			  return;
		  }
		  this.$confirm('解除设备注册，设备将不能正常使用?',"提示",{
		  }).then(() => {
			  let params = {
				  id:this.needData.id
			  }
			  resetExamSeat(params).then(res=>{
				  if (res.data.success){
					  // console.log(res)
					  this.getListData()
					  this.$message({
						  message:res.data.message,
						  type:'success'
					  })
				  }else {
					  this.$message({
						  message:res.data.message,
						  type:'warning'
					  })
				  }
			  })
		  }).catch(() => {})
			
	  },
    rowClass () {
      return 'padding:8px!important;'
    },
    callFunction(item) {
      this[item.func].apply(this, item);
    },
    getListData(){
      this.pages = {
        pageIndex: 1,
        pageSize: 20,
      }
      this.getDataList()
    },
    // 列表查询接口
    getDataList(){
      // console.log(this.filters.skillType,'skill')
	    const userInfo = JSON.parse(window.localStorage.user)
	    // console.log(userInfo,'info')
	    const ids = this.filters.skillType.map(item => {
		    return item[1]
	    })
      let params = {
	      // cityCode:userInfo.cityCode,
	      // districtCode:userInfo.districtCode,
	      // provinceCode:userInfo.provinceCode,
	      pageIndex: this.pages.pageIndex,//页码
        pageSize: this.pages.pageSize,//页数
        departmentId:ids.join(',')||userInfo.departmentId,//组织机构ID
        examSeatCode:undefined,//注册码
        examSeatStatus:this.filters.examSeatStatus,//启动状态（ 1：启动，2：禁用 ）
	      registerStatus:this.filters.registerStatus,//启动状态（ 1：启动，2：禁用 ）
        examSeatTypeId:this.filters.register,//考位类型ID
        machineCode:undefined,//设备码
        // registerStatus:undefined,//注册状态
      }
      this.listLoading = true
	    this.examTypes()
      getExamSeatList(params).then(res => {
        var result = res.data
        if(result.success) {
          this.tableData = result.response.data
          this.pages.dataCount = result.response.dataCount
        }
        
        this.listLoading = false
      })
    },
    //组织管理
    getOrganizations() {
      var _this = this
	    const userInfo = JSON.parse(window.localStorage.user)
	    // console.log(userInfo,'info')
      var params = {
        parentId: userInfo.departmentId
      }
      if(this.filters.selectc === ''){}else{
        params.isEnable = this.filters.selectc
      }
      // if(this.filters.name){
      //   params.name = this.filters.name
      // }
      this.listLoading = true
      getOrganizationTree(params).then(res => {
        var data = res.data
        var resData = [data.response]
        // console.log(resData,'res.da')
        resData.push()
        if(data.success){

          this.selectVal = ''
          this.listLoading = false
          if(data.response.name == "根节点"){
            var newObjData = JSON.stringify(data.response.children).replace(/\hasChildren/g,"HasChildren")
            _this.organizeNums = JSON.parse(newObjData)
            // console.log(_this.organizeNums,'11111')
          }else{
            _this.organizeNums = resData
          }
          // _this.treeData = resData
        }
      })
    },
    //考位类型
    async examTypes(){
      let params = {
        pageIndex:1,
        pageSize:20,
	      ddIdExamSeatStatus:1,
      }
      getExamSeatTypeList(params).then(res=>{
        if (res.data.success){
          this.examPosition = res.data.response.data
        }
      })
      //组织管理
      this.getOrganizations()

      // //职业功能
      // let params1 = {
      //   pageIndex:1,
      //   pageSize:20,
      // }
      // getOproomProfessionFuncList(params1).then(res1=>{
      //   if (res1.data.success){
      //     this.professionType = res1.data.response.data
      //   }
      // })

      // //职业等级方向
      // let params2 = {
      //   pageIndex:1,
      //   pageSize:20,
      // }
      // getOproomProfessionDirList(params2).then(res2=>{
      //   if (res2.data.success){
      //     this.registers = res2.data.response.data
      //   }
      // })

      //根据考位类型获取鉴定点个数
      // let params3 = {
      //   pageIndex:1,
      //   pageSize:20,
      // }
      // getExamPointNumByExamSeatType(params3).then(res3=>{
      //   if (res3.data.success){
      //     this.pointNums = res3.data.response
      //   }
      // })
    },
    async handleAdd(){
      this.addForm = {
        ddIdExamSeatStatus:2,//考位状态 （1，启用；2，禁用）
        departmentId:[],//组织结构ID
        examSeatTypeId:undefined,//考位类型ID
        name:undefined,//考位名称
        schoolCode:undefined,//学校编号
	      oproomBrandId:undefined,
      }
      this.dialogs.addType.dialog = true;
    },
    handleChange(value) {
      console.log(value);
      // console.log(this.addForm.departmentId,'444');
    },
    async addConfirm(){
      let params = {
        ...this.addForm,
        
      }
	    console.log(this.addForm.departmentId,'departmentid')
			params.departmentId=this.addForm.departmentId&&this.addForm.departmentId.length>0?this.addForm.departmentId[this.addForm.departmentId.length-1]:undefined
			if (!this.addForm.oproomBrandId){
				params.oproomBrandId = 0
			}
      const res = await addExamSeat(params)
      if (res.data.success){
        this.getListData()
        this.dialogs.addType.dialog = false ;
        this.$message({
          message:res.data.message,
          type:'success'
        })
      }else{
        this.$message({
          message:res.data.message,
          type:'warning'
        })
      }
    },
    handleEdit(){
      if (!this.needData){
        this.$message({
          message:'请选择一条数据',
          type:'warning'
        })
        return;
      }
			this.brandList = []
	    // const objBrand = this.examPosition.filter(item=>item.id===this.needData.examSeatTypeId)
	    // this.brandList = objBrand[0].brandList
	    // console.log(this.brandList,'brandList')
	    // if (objBrand[0].isRelationBrand){
		  //   this.brandIsDisable = true
	    // }else {
		  //   this.brandIsDisable = false
	    // }
	    
	   
      this.editFalse = true;
      this.addForm = {
        ...this.needData,
        // departmentId:this.addForm.departmentId[2]
      }
			this.addForm.oproomBrandId =this.needData.oproomBrandId
	    if (!this.needData.oproomBrandId){
		    this.addForm.oproomBrandId = undefined
	    }
      this.dialogs.addType.dialog = true;
    },
    async editConfirm(){
      let params = {
        ddIdExamSeatStatus:this.addForm.ddIdExamSeatStatus,
        id:this.needData.id,
        name:this.addForm.name,
	      // oproomBrandId : this.addForm.oproomBrandId?this.addForm.oproomBrandId:0,
      }
      const res = await updateExamSeat(params)
      if (res.data.success){
        this.getDataList()
        this.dialogs.addType.dialog = false ;
        this.$message({
          message:res.data.message,
          type:'success'
        })
      }else{
        this.$message({
          message:res.data.message,
          type:'warning'
        })
      }
      this.editFalse = false;
    },
    //禁用
    async disable(){
      if (!this.needData){
        this.$message({
          message:'请选择一条数据',
          type:'warning'
        })
        return;
      }
      this.addForm = {
        ...this.needData,
      }
      let params = {
        id:this.needData.id,
        name:this.addForm.name,
        ddIdExamSeatStatus:2,//状态，1:启用;2:禁用
      }
      const res = await updateExamSeat(params)
      if (res.data.success){
        this.getDataList()
        this.dialogs.addType.dialog = false ;
        this.$message({
          message:res.data.message,
          type:'success'
        })
      }else{
        this.$message({
          message:res.data.message,
          type:'warning'
        })
      }

    },
    //启用
    async enable(){
      if (!this.needData){
        this.$message({
          message:'请选择一条数据',
          type:'warning'
        })
        return;
      }
      this.addForm = {
        ...this.needData,
      }
      let params = {
        id:this.needData.id,
        name:this.addForm.name,
        ddIdExamSeatStatus:1,//状态，1:启用;2:禁用
      }
      const res = await updateExamSeat(params)
      if (res.data.success){
        this.getDataList()
        this.dialogs.addType.dialog = false ;
        this.$message({
          message:res.data.message,
          type:'success'
        })
      }else{
        this.$message({
          message:res.data.message,
          type:'warning'
        })
      }

    },
    //批量生成考位
    batchExamination(){
			if (this.loading){
				this.$message({
					message:'正在生成中'
				})
				return;
			}
			this.loading = true
      let params = {
				num:1,
      }
      addBatchExamSeat(params).then(res=>{
        if (res.data.success){
          this.getListData();
          this.$message({
            message:res.data.message,
            type:"success"
          })
        }
        else {
          this.$message({
            message:res.data.message,
            type:"warning"
          })
        }
	      this.loading = false ;
      })
    },




    // 获取省
    getAreaList() {
      var params = {
        parentId: 1,
      }
      getAreaDictionaryList(params).then(res => {
        if (res.data.success) {
          this.areaArr = res.data.response.reverse();
        }
      })
    },
    //获取字符空间
    async getNamespace(){
      let params = {
        pageIndex:undefined,
        pageSize:undefined,
      }
      const res = await  getByNamespace(params)
      // console.log(res,'res')
    },


    // 点击table某一行
    currentChange(val) {
      this.needData = val
      console.log(val,'val')
    },
    // 分页
    handleCurrentChange(val) {
      this.pages.pageIndex = val;
      this.getDataList();
    },
    handleSizeChange(val){
      this.pages.pageSize = val;
      this.getDataList();
    },
    // 时间格式化
    formatCreateTime: function(row, column) {
      return !row || row == ""
          ? ""
          : util.formatDate.format(new Date(row), "yyyy-MM-dd hh:mm");
    },
    // 表格数据分页条数累计
    indexMethod(index) {
      var currentPage = this.pages.pageIndex
      var pageSize = this.pages.pageSize
      return (index + 1) + (currentPage - 1) * pageSize
    },
  },
  watch: {
	  // 'needData':function(newVal,oldVal){
		//   const objBrand = this.examPosition.filter(item=>item.id===this.needData.examSeatTypeId)
		//   this.brandList = objBrand[0].brandList
		//   console.log(this.brandList,'brandList')
		//   if (objBrand[0].isRelationBrand){
		// 	  this.brandIsDisable = true
		//   }else {
		// 	  this.brandIsDisable = false
		//   }
    // }
  },
  created(){
    this.utils = util
    let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
    this.buttonList = getButtonList(this.$route.path, routers);
    this.getListData();
    this.getAreaList()
  },
}
</script>

<style lang="stylus" scoped>
.projectName {
  text-align: center;
}
.projectSelect {
  margin-bottom: 15px;
}
.projectTitle {
  margin-bottom: 15px
}
.replyBtn {
  margin: 10px 0;
  color: #409eff;
  cursor: pointer;
  width: 30px;
}
.replyMessage {
  background: #eee;
  line-height: 30px;
  padding: 5px;
  border-radius: 2px;
  margin: 5px 15px;
}
.titleTimer {
  margin-left: 10px;
}
.green_t {
  color: green;
}
.red_t {
  color: red;
}
</style>
